html {
  font-family: Inter, sans-serif;
  background-color: #000000;
  overflow-x: hidden;
  height: 100%;
}

body {
  color: white;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

/* hide scrollbar */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

h1 {
  font-size: 3.6em;
  margin: 0;
  font-weight: normal;
  letter-spacing: 0.05em;
}

/* header */
h2 {
  font-size: 1.733em;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

/* subheader */
h3 {
  font-size: 1.2em;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

/* boldparagraph */
h4 {
  font-size: 1em;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.025em;
}

/* tinyheader */
h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 1.25em;
  margin: 0;
}

/* footer */
h6 {
  font-size: 0.767em;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.025em;
}

/* text */
p {
  font-size: 0.9375em;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  /* letter-spacing: 0.025em; */
}

a {
  letter-spacing: 0.025em;
}

* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-size: 89%;
}

button {
  letter-spacing: 0.025em;
}

input[type='date'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

svg {
  vertical-align: top;
}
